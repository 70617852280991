import {convertFilters, directusClient} from "../../hooks/useDirectus";

const messagingFields = [`id`, `status`, `created_on`, `subject`, `content`, `is_read`, `owner.id`, `from_user.id`, `to_user.id`];

const getMessage = (messageId) => {
    return directusClient.getItem(`message`, messageId);
}
const getMessages = (filters) => {
    const { apiFilters, limit, page, sort } = convertFilters(filters);
    return directusClient.getItems(`message`, {
        fields: messagingFields,
        filter: apiFilters,
        limit,
        page,
        sort
    });
}
const createMessage = ({ toUserId, subject, content }) => {
    const message = { to_user: Number(toUserId), subject, content };
    return directusClient.post(`/custom/messages`, message);
}
// Permissions set on Users role in Directus to only allow limited editing
const updateMessage = ({ messageId, isRead }) => {
    const updates = {
        is_read: isRead ? 1 : 0,
    };
    return directusClient.updateItem(`message`, messageId, updates);
}
const deleteMessage = (id) => {
    return directusClient.updateItem(`message`, id, {status: `deleted`});
}

export {
    getMessage,
    getMessages,
    createMessage,
    updateMessage,
    deleteMessage
}
