import * as React from 'react';
import { Link } from "gatsby";
import {Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar} from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InventoryIcon from '@mui/icons-material/Inventory';
import HelpIcon from '@mui/icons-material/Help';
import SearchIcon from '@mui/icons-material/Search';
import ListAltIcon from '@mui/icons-material/ViewList';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import VisibilityIcon from '@mui/icons-material/Visibility';
import StoreIcon from '@mui/icons-material/Store';
import StorefrontIcon from '@mui/icons-material/Storefront';
import OfferedInTradeIcon from "../images/ui/icons/OfferedInTrade";
import {useAuth} from "../context/authContext";
import {useIsMounted} from "./hooks";
import {LoginButton} from "../features/account/LoginButton";
import {RegisterButton} from "../features/account/RegisterButton";
import {useMerchantOnboardingProgress} from "../features/merchants/hooks";

export const Nav = ({ container, drawerWidth, handleDrawerToggle, mobileOpen, setMobileOpen, variant }) => {
    const desktopDrawerWidth = variant === `home` ? 0 : drawerWidth;
    const useStyles = makeStyles(theme => ({
        drawer: {
            [theme.breakpoints.up(`md`)]: {
                width: desktopDrawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            width: drawerWidth,
            border: `none`
        },
        logo: {
            margin: `.5rem 0`,
            [theme.breakpoints.up(`md`)]: {
                margin: `1rem auto`,
                width: `90%`,
            },
        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    const closeMobile = () => {setMobileOpen(false)};

    return (
        <nav aria-label="Main navigation" className={classes.drawer}>
            <Hidden implementation="css" mdUp>
                <Drawer
                    ModalProps={{keepMounted: true, /* Better open performance on mobile.*/}}
                    PaperProps={{elevation:16}}
                    anchor={theme.direction === `rtl` ? `right` : `left`}
                    classes={{paper: classes.drawerPaper}}
                    container={container}
                    onClose={handleDrawerToggle}
                    open={mobileOpen}
                    variant="temporary"
                >
                    <NavContent closeMobile={closeMobile} handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
            </Hidden>
            { variant !== `home` &&
            <Hidden implementation="css" smDown>
                <Drawer
                    PaperProps={{elevation:1}}
                    classes={{paper: classes.drawerPaper}}
                    open
                    variant="permanent"
                >
                    <NavContent closeMobile={closeMobile} handleDrawerToggle={handleDrawerToggle} />
                </Drawer>
            </Hidden>
            }
        </nav>
    )
};

const NavContent = ({ closeMobile, handleDrawerToggle }) => {
    const useStyles = makeStyles(theme => ({
        logo: {
            margin: `.5rem 0`,
            [theme.breakpoints.up(`md`)]: {
                margin: `1rem auto`,
                width: `90%`,
            },
        }
    }));
    const classes = useStyles();
    const { isLoading, allCompleted: setupCompleted } = useMerchantOnboardingProgress();
    const isMounted = useIsMounted();
    const {isLoggedIn, logout} = useAuth();

    return (
        <>
            <Hidden implementation="css" smDown>
                <Link to={`/games/`}>
                    <img alt="CCG Trader" className={classes.logo} height="111" src="/images/ui/logo.svg" width="216" />
                </Link>
            </Hidden>
            <Hidden implementation="css" mdUp>
                <Toolbar className="">
                    <Link to={`/games/`}>
                        <img alt="CCG Trader" className={classes.logo} height="73" src="/images/ui/logo-no-strapline.svg" width="172" />
                    </Link>
                    <IconButton aria-label="close" color="inherit" edge="end" onClick={handleDrawerToggle}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
            </Hidden>
            <List>
                <ListItem button component={Link} key="Database" onClick={closeMobile} to="/games/">
                    <ListItemIcon><VisibilityIcon /></ListItemIcon>
                    <ListItemText primary="CCG Database" />
                </ListItem>
                <ListItem button component={Link} key="Search" onClick={closeMobile} to="/search?page=1&q=detective%20pikachu">
                    <ListItemIcon><SearchIcon/></ListItemIcon>
                    <ListItemText primary="Card Search" />
                </ListItem>
                <ListItem button component={Link} key="Marketplace" onClick={closeMobile} to="/marketplace/">
                    <ListItemIcon><StorefrontIcon/></ListItemIcon>
                    <ListItemText primary="Card Marketplace" />
                </ListItem>
                {isLoggedIn() ? (
                    <>
                        <ListItem button component={Link} key="Buy" onClick={closeMobile} to="/dashboard/">
                            <ListItemIcon><InventoryIcon/></ListItemIcon>
                            <ListItemText primary="Card Purchases" />
                        </ListItem>
                        <SalesNavButton closeMobile={closeMobile} isLoading={isLoading} setupCompleted={setupCompleted} />
                        <ListItem button component={Link} key="Trade" onClick={closeMobile} to="/dashboard/trade/">
                            <ListItemIcon><OfferedInTradeIcon/></ListItemIcon>
                            <ListItemText primary="Card Trades" />
                        </ListItem>
                        <ListItem button component={Link} key="Collection" onClick={closeMobile} to="/dashboard/my-games/">
                            <ListItemIcon><ListAltIcon/></ListItemIcon>
                            <ListItemText primary="Card Collection" />
                        </ListItem>
                    </>
                ) : (
                    <>
                        <LoginButton buttonText={`Card Purchases`} icon={<InventoryIcon/>} redirect="/dashboard/" type="listItem" />
                        <LoginButton buttonText={`Card Sales`} icon={<StoreIcon/>} redirect="/dashboard/sell/" type="listItem" />
                        <LoginButton buttonText={`Card Trades`} icon={<OfferedInTradeIcon/>} redirect="/dashboard/trade/" type="listItem" />
                        <LoginButton buttonText={`Card Collection`} icon={<ListAltIcon/>} redirect="/dashboard/my-games/" type="listItem" />
                    </>
                )}
                <ListItem button component={Link} key="Help" onClick={closeMobile} to="/help/">
                    <ListItemIcon><HelpIcon/></ListItemIcon>
                    <ListItemText primary="Help / FAQ" />
                </ListItem>
            </List>
            <Divider />
            <List>
                {isMounted ? isLoggedIn() ? (
                    <ListItem button key="Logout" onClick={event => { logout(event); }}>
                        <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                ) : (
                    <>
                        <LoginButton buttonText={`Login`} redirect="/games" type="listItem" />
                        <RegisterButton buttonText={`Join`} redirect="/games" type="listItem" />
                    </>
                ) : null }
            </List>
        </>
    )
};

const SalesNavButton = ({ closeMobile, isLoading, setupCompleted }) => {
    return isLoading ?
        <ListItem button component={Link} disabled key="Sales" to="/dashboard/sell/">
            <ListItemIcon><StoreIcon/></ListItemIcon>
            <ListItemText primary="Card Sales" />
        </ListItem>
        : setupCompleted ?
        <ListItem button component={Link} key="Sales" onClick={closeMobile} to="/dashboard/sell/">
            <ListItemIcon><StoreIcon/></ListItemIcon>
            <ListItemText primary="Card Sales" />
        </ListItem>
        :
        <ListItem button component={Link} key="Start Selling" onClick={closeMobile} to="/dashboard/start-selling/">
            <ListItemIcon><StoreIcon/></ListItemIcon>
            <ListItemText primary="Start Selling Cards" />
        </ListItem>
}
