import * as React from 'react';
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import {directusClient} from "../hooks/useDirectus";

const useIsMounted = () => {
    const [isMounted, setIsMounted] = React.useState(false)
    React.useEffect(() => {
        setIsMounted(true)
    }, [])
    return isMounted
}

export function useFile(fileId) {
    return useQuery([`file`, fileId], () => getFile(fileId));
}

async function getFile(fileId) {
    let {data: fileData} = await directusClient.get(`/files/${fileId}`);
    return fileData
}

useFile.propTypes = {
    fileId: PropTypes.number.isRequired
};

export { useIsMounted }
