import * as React from 'react';
import PropTypes from "prop-types";
import {AppBar, Grid, Paper, Toolbar, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {useSiteMetadata} from "../hooks/useSiteMetadata";
import {Header} from "./Header";
import {Nav} from "./Nav";
import {Footer} from "./Footer";
import {LowPriorityMessage} from "../features/alerts/LowPriorityMessage";
import {FeedbackForm} from "./FeedbackForm";
import {SocialFollow} from "./SocialFollow";
import {SlideOverCart} from "../features/cart/SlideOverCart";
import {useIsMounted} from "./hooks";
import {useAuth} from "../context/authContext";
import {ErrorFallback} from "./ErrorFallback";
import {ErrorBoundary} from "react-error-boundary";
import {GoogleAdSense} from "./GoogleAdSense";
import {Link} from "gatsby";

export function Layout({ children, variant }) {
    const isMounted = useIsMounted();
    const {isLoggedIn} = useAuth();
    const { title } = useSiteMetadata();
    const drawerWidth = 240;
    const desktopDrawerWidth = variant === `home` ? 0 : drawerWidth;
    const useStyles = makeStyles(theme => ({
        contentPaper: {
            [theme.breakpoints.up(`md`)]: {
                marginLeft: desktopDrawerWidth,
            },
        },
        themeRoot: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.contrastText
        }
    }));
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div className={`flex flex-col min-h-screen ${classes.themeRoot}`} id="layout">
            <LowPriorityMessage />
            <Header handleDrawerToggle={handleDrawerToggle} siteTitle={title} />
            <Nav drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} variant={variant} />

            <AppBar className={`${classes.contentPaper}`} color={`secondary`} elevation={1} position="static">
                <Toolbar>
                    <Typography variant="h6">We're becoming a community project. <Link className="underline" to="/news">Find out more...</Link></Typography>
                </Toolbar>
            </AppBar>

            <section className={`p-4 sm:p-8 ${classes.contentPaper}`}>
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => {
                        // reset the state of your app so the error doesn't happen again
                    }}
                >
                    {children}
                </ErrorBoundary>
            </section>

            <Paper className={`p-4 sm:p-8 ${classes.contentPaper}`}>
                <Grid container spacing={3}>
                    <Grid item lg={5} md={5} sm={12} xl={4} xs={12}>
                        <FeedbackForm />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xl={4} xs={12}>
                        <SocialFollow />
                    </Grid>
                    <Grid className="text-center" item lg={3} md={3} sm={6} xl={4} xs={12}>
                        <h4 className="text-lg uppercase">Support</h4>
                        <nav className="">
                            <p><a className="opacity-50 focus:opacity-100 hover:opacity-100" href="https://ccgtrader.atlassian.net/servicedesk/" rel="nofollow noreferrer" target="_blank">Service Desk</a></p>
                            <p><a className="opacity-50 focus:opacity-100 hover:opacity-100" href="https://ccgtrader.atlassian.net/servicedesk/customer/portal/1/group/1/create/6" rel="nofollow noreferrer" target="_blank">Report a Bug</a></p>
                        </nav>
                    </Grid>
                </Grid>
            </Paper>
            <Footer className={classes.contentPaper} />
            {isMounted && isLoggedIn() && <SlideOverCart />}
            <GoogleAdSense publisherId={7976325504735047} />
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
};
