import * as React from 'react';
import PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {useUIDispatch} from "../../app/uiContext";
import {useCart} from "./hooks";

export const OpenCartButton = ({ tooltip = `Open cart` }) => {
    const { isLoading, data } = useCart();
    const color = !isLoading && data && data.data.length > 0 ? `primary` : undefined;
    const uiDispatch = useUIDispatch();
    const handleOpenCart = async () => {
        uiDispatch({type:`SHOW_CART`});
    }

    return (
        <>
            <Tooltip title={tooltip}>
                <span>
                    <IconButton
                        aria-label={tooltip}
                        color={color}
                        disabled={false}
                        onClick={handleOpenCart}
                    >
                        <ShoppingCartIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    )
};

OpenCartButton.propTypes = {
    tooltip: PropTypes.string
}
