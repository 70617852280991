import {useMutation, useQuery, useQueryClient} from "react-query";
import {v1 as uuidv1} from "uuid";
import {useDispatch} from "react-redux";
import {addMessage} from "../alerts/messagesSlice";
import {getMessage, getMessages, createMessage, updateMessage, deleteMessage} from "./client";
import {useUser} from "../users/hooks";

const oneMinute = 60 * 1000;
const staleTime = 5 * oneMinute;

const messageKeys = {
    all: [`message`],
    list: (filters) => [...messageKeys.all, { filters }],
    detail: (messageId) => [...messageKeys.all, `detail`, messageId]
}

const useMessageCount = ({ filters = { isRead: false } } = {}) => {
    const results = useMessagesIn({ filters });
    const count = results && results.data && results.data.data.length;
    return {...results, data: { count }};
}
const useMessage = (messageId) => {
    return useQuery(messageKeys.detail(messageId), () => getMessage(messageId), { enabled:!!messageId, staleTime });
}
const useMessagesIn = ({ filters = {} }) => {
    const user = useUser();
    const toUserId = user && user.data && Number(user.data.data.id);
    const combinedFilters = { status: `published`, toId: toUserId, sort:`-created_on`, ...filters };
    return useQuery(messageKeys.list(combinedFilters), () => getMessages(combinedFilters), { enabled:!!toUserId, staleTime });
}
const useMessagesSent = ({ filters = {} }) => {
    const user = useUser();
    const fromUserId = user && user.data && Number(user.data.data.id);
    const combinedFilters = { status: `published`, fromId: fromUserId, sort:`-created_on`, ...filters };
    return useQuery(messageKeys.list(combinedFilters), () => getMessages(combinedFilters), { enabled:!!fromUserId, staleTime });
}
const useCreateMessage = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(createMessage,{
        onError: (err) => {
            dispatch(addMessage({id:uuidv1(), text:`Failed creating message: ${err}`, priority: `low`}));
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(messageKeys.list()).then();
        }
    });
}
const useUpdateMessage = () => {
    const queryClient = useQueryClient();
    return useMutation(updateMessage, {
        onMutate: async messageUpdates => {
            await queryClient.cancelQueries(messageKeys.detail(messageUpdates.id));
            const previous = queryClient.getQueryData(messageKeys.detail(messageUpdates.id));
            queryClient.setQueryData(messageKeys.detail(messageUpdates.id), {...previous, ...messageUpdates});
            return {previous, messageUpdates};
        },
        onError: (err, messageUpdates, context) => {
            queryClient.setQueryData(messageKeys.detail(messageUpdates.id), context.previous);
        },
        onSettled: (data, variables) => {
            queryClient.invalidateQueries(messageKeys.all).then();
        }
    });
}
const useDeleteMessage = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteMessage, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(messageKeys.all).then();
        }
    });
}

export {
    messageKeys,
    useMessageCount,
    useMessage,
    useMessagesIn,
    useMessagesSent,
    useCreateMessage,
    useUpdateMessage,
    useDeleteMessage
}
