import * as React from 'react';
import PropTypes from "prop-types";
import {AppBar, IconButton, Toolbar, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@mui/icons-material/Menu";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Link } from "gatsby";
import {useAuth} from "../context/authContext";
import {UserAccountNav} from "../features/account/UserAccountNav";
import {useIsMounted} from "./hooks";
import {OpenCartButton} from "../features/cart/OpenCartButton";
import {MessagesButton} from "../features/messaging/MessagesButton";
import {CurrencySelector} from "../features/account/CurrencySelector";

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(`md`)]: {
            display: `none`,
        },
    },
    logo: {
        maxWidth: `50%`,
        paddingTop: `0.5rem`,
        marginLeft: `auto`,
        marginRight: `auto`,
        [theme.breakpoints.up(`sm`)]: {
            maxHeight: `3rem`,
            position: `absolute`,
            top: `0.5rem`,
            left: `0.5rem`,
        },
    },
    title: {
        flexGrow: 1,
        display: `block`,
    },
}));

export const Header = ({ siteTitle = ``, handleDrawerToggle }) => {
    const isMounted = useIsMounted();
    const classes = useStyles();
    const {isLoggedIn} = useAuth();

    return (
        <div>
            <AppBar color={`inherit`} elevation={1} position="static">
                <Toolbar>
                    {handleDrawerToggle ? (
                        <>
                            <IconButton aria-label="menu" className={classes.menuButton} color="inherit" edge="start" onClick={handleDrawerToggle}>
                                <MenuIcon/>
                            </IconButton>
                            <Link className="py-1" to="/games/">
                                <img alt="CCG Trader" className="h-16" src="/images/ui/logo-no-strapline.svg" />
                            </Link>
                            <Typography className={classes.title} variant="h6">
                            </Typography>
                        </>
                    ) : (
                        <>
                            <img alt="CCG Trader" className={classes.logo} src="/images/ui/logo-no-strapline.svg" />
                            <div className={classes.title} />
                        </>
                    )}
                    <nav className="flex items-center ml-4">
                        <IconButton aria-label="Latest news" component={Link} to="/news/">
                            <NewReleasesIcon />
                        </IconButton>
                        {isMounted && <CurrencySelector />}
                        {isMounted && isLoggedIn() && (
                            <>
                                <MessagesButton />
                                <OpenCartButton />
                                <UserAccountNav />
                            </>
                        )}
                    </nav>

                </Toolbar>
            </AppBar>
        </div>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string
};
