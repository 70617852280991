import * as React from "react";
import {Skeleton} from "@material-ui/lab";
import {Typography} from "@material-ui/core";
import {LocalPrice} from "../../common/LocalPrice";

export const CartTotalPrice = ({ cart }) => {
    const [cartTotal, setCartTotal] = React.useState(0);
    React.useEffect(() => {
        if (cart) {
            const tot = cart.data.reduce((accumulator, currentValue) => accumulator + currentValue.attributes.subtotal_amount_cents, 0);
            setCartTotal(tot);
        }
    }, [cart]);

    if (!cart) { return <Typography className="float-right font-bold w-1/3"><Skeleton animation="pulse" variant="text" /></Typography> }
    if (cart.data.length === 0) { return null }

    return <LocalPrice className="float-right font-bold" fromCurrencyCode={cart.data[0].attributes.currency_code} fromPrice={cartTotal} />
};
