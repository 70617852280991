import * as React from "react";
import {Skeleton} from "@material-ui/lab";
import {LineItem} from "./LineItem";

export const Order = ({ lineItems, order }) => {
    return order
        ? lineItems.map((item) => (
                <LineItem key={item.id} product={item} />
        ))
        : <Skeleton animation="pulse" height={400} variant="rect" />;
};
