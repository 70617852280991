import * as React from 'react';
import PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import {useDeleteLineItem} from "./hooks";

export const DeleteCartItemButton = ({itemId, tooltip = `Delete`}) => {
    const mutation = useDeleteLineItem();
    const handleDelete = async () => {
        mutation.mutate({ id: itemId });
    }

    return (
        <Tooltip title={tooltip}>
                <span>
                    <IconButton
                        aria-label={tooltip}
                        color="primary"
                        disabled={mutation.isLoading}
                        onClick={handleDelete}
                    >
                        <DeleteIcon />
                    </IconButton>
                </span>
        </Tooltip>
    )
};

DeleteCartItemButton.propTypes = {
    itemId: PropTypes.string.isRequired,
    tooltip: PropTypes.string
}
