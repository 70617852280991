import * as React from "react";
import PropTypes from "prop-types";

export const GoogleAdSense = ({ publisherId }) => {
    React.useEffect(() => {
        const headID = document.getElementsByTagName('head')[0]
        const gaScript = document.createElement(`script`)
        gaScript.async = true
        gaScript.crossOrigin = `anonymous`
        gaScript['data-ad-client'] = `ca-pub-${publisherId}`
        gaScript.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js`
        gaScript.type = `text/javascript`
        headID.appendChild(gaScript)
    }, [])

    return null;
}

GoogleAdSense.propTypes = {
    publisherId: PropTypes.number.isRequired
}
